import './App.css';
import './App.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from './pages/Login/login';
import Home from './pages/Home/home';
import Questions from './components/Questions/questions';
import Subjects from './components/Subjects/subjects';
import Courses from './components/Courses/courses';
import Topics from './components/Topics/topics';
import LearningOutcomes from './components/LearningOutcomes/learningOutcomes';
import Essays from './components/Essays/essays';
import Labels from './components/Labels/labels';
import Context from './user_details';
import Subscriptions from './components/Subscriptions/subscriptions';
import {useState, useEffect} from 'react';
import Default from './components/Default/default';
import Cookies from 'js-cookie';
import ForgotPassword from './components/ForgotPassword/forgotPassword';
import permissionsHelper from './helpers/permissionsHelper'
import GenerateQuestions from './components/QuestionsGenerator/questionsGenerator';


function App() {
  const [isLoggedIn,setIsLoggedIn]=useState(Cookies.get("nexus_qBank_login"));
  const[userDetails, setUserDetails] = useState({});
  useEffect(()=>{
    console.log(userDetails,"user details")
  },[userDetails]);

  const SwitchLoginStatus=(Status)=>{
    setIsLoggedIn(Status)
    Cookies.set("nexus_qBank_login",Status,{expires:9999})
  }
  
  const getUserData = async() => {
    await fetch(`${process.env.REACT_APP_API_URL}/user/`+Cookies.get("nexus_qBank_id"))
          .then(async response => {
            const data = await response.json();
            if(data.success){
              const allPermissionsIds = await permissionsHelper.modifyAndReturnPermissionIds(data.result.selectedRoles);
              var allUserPermissionTypes = [];
              await Promise.all(
              allPermissionsIds.map(async permissionId => {
                const permissionType = await permissionsHelper.getPermissionTypeByPermissionId(permissionId);
                if (permissionType) {
                  allUserPermissionTypes.push(permissionType);
                }
              })
              )
              setUserDetails({...data.result, permissions: allPermissionsIds, permissionTypes: allUserPermissionTypes});
            }
            else {
              Cookies.remove("nexus_qBank_id");
              Cookies.remove("nexus_qBank_login");
              
            }
          })
          .catch(err => {
            alert("something went wrong")
          })
  }
  if(!isLoggedIn){
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails}/>} />
          <Route path="forgotPassword" element={<ForgotPassword/>}/>
          <Route path="*" element={<Login/>}/>
        </Routes>
      </BrowserRouter>
    )
  }
  else{
    if(Object.keys(userDetails).length > 0){
 return (
  <Context.Provider value={{user: userDetails}}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}>
                <Route exact path="" element={<Default/>}/>
                <Route path="questions" element={<Questions/>}/>
                <Route path="generateQuestions" element={<GenerateQuestions/>}/>
                <Route path="subjects" element={<Subjects/>}/>
                <Route path="courses" element={<Courses/>}/>
                <Route path="topics" element={<Topics/>}/>
                <Route path="learning-outcomes" element={<LearningOutcomes/>}/>
                <Route path="essays" element={<Essays/>}/>
                <Route path="labels" element={<Labels/>}/>
                <Route path="clients" element={<Subscriptions/>}/>
              </Route>
            </Routes>
         </BrowserRouter>
  </Context.Provider>

 )}
else{
  getUserData();
}
}
}

export default App;
